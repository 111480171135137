:root {

    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #12AF23;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
}



.Toastify__toast {
    background-color: var(--toastify-color-light);
    color: var(--toastify-color-dark);

}



.Toastify__toast--info {
    border-left: 5px solid var(--toastify-color-info);
}

.Toastify__toast--success {
    border-left: 5px solid var(--toastify-color-success);
}

.Toastify__toast--warning {
    border-left: 5px solid var(--toastify-color-warning);
}

.Toastify__toast--error {
    border-left: 5px solid var(--toastify-color-error);
}


.Toastify__toast-theme--light {
    color: var(--toastify-color-dark);

}

.Toastify__toast-theme--light {
    background-color: var(--toastify-color-light);
}

.custom-toast{
    gap: 14px;
}
.custom-toast svg{
    font-size: 22px ;
}
.custom-toast p{
margin: 0 !important;
font-size: 14px;


}
.custom-toast span{
    font-size: 12px;
    color: #989A9D;
}
